import { useMutation } from 'react-query';
import useERC20Contract from './useERC20Contract';

const useErc20Approve = (tokenAddress, { onSuccess, onError } = {}) => {
  const erc20Contract = useERC20Contract(tokenAddress);

  const checkAllowance = async (spender, amountInWei) => {
    const currentAllowance = await erc20Contract.allowance(
      await erc20Contract.signer.getAddress(),
      spender
    );

    return currentAllowance.gte(amountInWei);
  };

  const approveWithCheck = async ({ spender, amountInWei }) => {
    const isAllowanceSufficient = await checkAllowance(spender, amountInWei);

    if (isAllowanceSufficient) {
      return;
    }

    const approveTx = await erc20Contract.approve(spender, amountInWei);

    return await approveTx.wait();
  };

  const { mutateAsync: approve, isLoading } = useMutation(approveWithCheck, { onSuccess, onError });

  return { approve, isLoading };
};

export default useErc20Approve;
