import { ethers } from 'ethers';

const addxDaiChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x64', // A 0x-prefixed hexadecimal string
        chainName: 'xDai',
        nativeCurrency: {
          name: 'xDai',
          symbol: 'xDai', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://rpc.gnosischain.com'],
        blockExplorerUrls: ['https://blockscout.com/xdai/mainnet'],
      },
    ],
  });

  return true;
};

const addBscMainnetChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x38', // A 0x-prefixed hexadecimal string
        chainName: 'BSC Mainnet',
        nativeCurrency: {
          name: 'BSC',
          symbol: 'BNB', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com'],
      },
    ],
  });

  return true;
};

const addBscTestnetChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x61', // A 0x-prefixed hexadecimal string
        chainName: 'BSC Testnet',
        nativeCurrency: {
          name: 'BSC',
          symbol: 'BNB', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://data-seed-prebsc-2-s2.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
      },
    ],
  });

  return true;
};

const addAvalancheTestnetChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: ethers.utils.hexlify(43113), // A 0x-prefixed hexadecimal string
        chainName: 'Avalanche FUJI C-Chain',
        nativeCurrency: {
          name: 'AVAX',
          symbol: 'AVAX', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://testnet.snowtrace.io/'],
      },
    ],
  });

  return true;
};

const addAvalancheChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: ethers.utils.hexlify(43114), // A 0x-prefixed hexadecimal string
        chainName: 'Avalanche Mainnet C-Chain',
        nativeCurrency: {
          name: 'AVAX',
          symbol: 'AVAX', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://snowtrace.io/'],
      },
    ],
  });

  return true;
};

const addBaseChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: ethers.utils.hexlify(8453), // A 0x-prefixed hexadecimal string
        chainName: 'Base',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://mainnet.base.org'],
        blockExplorerUrls: ['https://basescan.org/'],
      },
    ],
  });

  return true;
};

const addMerlinChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: ethers.utils.hexlify(4200), // A 0x-prefixed hexadecimal string
        chainName: 'Merlin',
        nativeCurrency: {
          name: 'BTC',
          symbol: 'BTC', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://rpc.merlinchain.io'],
        blockExplorerUrls: ['https://scan.merlinchain.io'],
      },
    ],
  });

  return true;
};

const addArbitrumSepoliaTestnetChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x66EEE', // A 0x-prefixed hexadecimal string
        chainName: 'Arbitrum Sepolia Testnet',
        nativeCurrency: {
          name: 'SepoliaETH',
          symbol: 'ETH', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://sepolia-rollup.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://sepolia.arbiscan.io'],
      },
    ],
  });

  return true;
};

const addArbitrumChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0xA4B1', // A 0x-prefixed hexadecimal string
        chainName: 'Arbitrum Mainnet',
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://arbiscan.io/'],
      },
    ],
  });

  return true;
};

const addPolygonChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x89', // A 0x-prefixed hexadecimal string
        chainName: 'Polygon',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://polygonscan.com/'],
      },
    ],
  });

  return true;
};

const addPolygonTestnetChain = async () => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: '0x13881', // A 0x-prefixed hexadecimal string
        chainName: 'Polygon Testnet',
        nativeCurrency: {
          name: 'tMATIC',
          symbol: 'tMATIC', // 2-6 characters long
          decimals: 18,
        },
        rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
        blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
      },
    ],
  });

  return true;
};

const addEthereumMainnet = async () => {
  await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: '0x1' }],
  });

  return true;
};

const addGoerli = async () => {
  await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: '0x5' }],
  });

  return true;
};

export const addTokenToMetamask = (address, symbol, decimals, image) => {
  return window.ethereum
    .request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address,
          symbol,
          decimals,
          image,
        },
      },
    })
    .catch((error) => {
      console.error(error);
      return false;
    })
    .then((result) => {
      return result;
    });
};

export const getNetworkNameById = (chainId) => {
  if (+chainId === 100) {
    return 'xDai';
  }
  if (+chainId === 97) {
    return 'bsc-testnet';
  }
  if (+chainId === 80001) {
    return 'polygon-testnet';
  }
  if (+chainId === 43113) {
    return 'avalanche-testnet';
  }
  if (+chainId === 5) {
    return 'goerli';
  }
  if (+chainId === 1) {
    return 'ethereum';
  }
  if (+chainId === 56) {
    return 'bsc';
  }
  if (+chainId === 137) {
    return 'polygon';
  }
  if (+chainId === 43114) {
    return 'avalanche';
  }
  if (+chainId === 8453) {
    return 'base';
  }
  if (+chainId === 4200) {
    return 'merlin';
  }
  if (+chainId === 42161) {
    return 'arbitrum';
  }
  if (+chainId === 421614) {
    return 'arbitrum-sepolia';
  }
  return null;
};

export const getNetworkBasicNameById = (chainId) => {
  if (+chainId === 100) {
    return 'xDai';
  }
  if (+chainId === 1 || +chainId === 4) {
    return 'ethereum';
  }
  if (+chainId === 56 || +chainId === 97) {
    return 'bsc';
  }
  if (+chainId === 137 || +chainId === 80001) {
    return 'polygon';
  }
  if (+chainId === 43114 || +chainId === 43113) {
    return 'avalanche';
  }
  if (+chainId === 8453) {
    return 'base';
  }
  if (+chainId === 4200) {
    return 'merlin';
  }
  if (+chainId === 421613 || +chainId === 42161) {
    return 'arbitrum';
  }
  return null;
};

export const addNetwork = async (chainId, network) => {
  let res = false;
  if (network === 'xDai') {
    if (chainId !== '0x64') {
      res = await addxDaiChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'goerli') {
    if (chainId !== '0x5') {
      res = await addGoerli().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'ethereum') {
    if (chainId !== '0x1') {
      res = await addEthereumMainnet().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'bsc') {
    if (chainId !== '0x38') {
      res = await addBscMainnetChain().catch((error) => {
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'bsc-testnet') {
    if (chainId !== '0x61') {
      res = await addBscTestnetChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'avalanche') {
    if (chainId !== '0xa869') {
      res = await addAvalancheChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'avalanche-testnet') {
    if (chainId !== '0xa86a') {
      res = await addAvalancheTestnetChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'base') {
    if (chainId !== '0x2105') {
      res = await addBaseChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'merlin') {
    if (chainId !== '0x1068') {
      res = await addMerlinChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'arbitrum') {
    if (chainId !== '0xa4b1') {
      res = await addArbitrumChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'arbitrum-sepolia') {
    if (chainId !== '0x66eee') {
      res = await addArbitrumSepoliaTestnetChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'polygon') {
    if (chainId !== '0x89') {
      res = await addPolygonChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }
  if (network === 'polygon-testnet') {
    if (chainId !== '0x13881') {
      res = await addPolygonTestnetChain().catch((error) => {
        console.error(error);
        return false;
      });
    } else {
      res = true;
    }
  }

  return res;
};

export const addChain = async (currentNetworkId, wantedNetworkId) => {
  const checked = await addNetwork(currentNetworkId, getNetworkNameById(wantedNetworkId));

  return checked;
};
