import { useQuery } from 'react-query';
import { getPledgeTxMetadata } from 'services/apiService';

export default function useTxMetadataQuery(dealId, { enabled } = {}) {
  const { data: transactions, isFetching } = useQuery(
    ['tx-metadata', dealId],
    () => getPledgeTxMetadata(dealId),
    {
      enabled,
    }
  );

  return {
    transactions,
    isFetching,
  };
}
