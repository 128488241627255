import { PoolStatus } from 'contracts/poolManager/constants';

export const PoolLabel = {
  [PoolStatus.NotActive]: 'Not Active',
  [PoolStatus.OpenUnlocked]: 'Open Unlocked',
  [PoolStatus.OpenLocked]: 'Open Locked',
  [PoolStatus.Freezed]: 'Freezed',
  [PoolStatus.Canceled]: 'Canceled',
};

export const AllPoolActions = [
  { label: PoolLabel[PoolStatus.OpenUnlocked], status: PoolStatus.OpenUnlocked },
  { label: PoolLabel[PoolStatus.OpenLocked], status: PoolStatus.OpenLocked },
  { label: PoolLabel[PoolStatus.Freezed], status: PoolStatus.Freezed },
  { label: PoolLabel[PoolStatus.Canceled], status: PoolStatus.Canceled },
];

export const ValidPoolActions = {
  [PoolStatus.OpenUnlocked]: [PoolStatus.OpenLocked, PoolStatus.Freezed, PoolStatus.Canceled],
  [PoolStatus.OpenLocked]: [PoolStatus.OpenUnlocked, PoolStatus.Freezed, PoolStatus.Canceled],
  [PoolStatus.Freezed]: [PoolStatus.OpenUnlocked, PoolStatus.OpenLocked, PoolStatus.Canceled],
};

export const PoolStatusDescription = [
  'Not Active - Default',
  'Open Unlocked - Pledge allowed, release allowed for users. Transition allowed.',
  'Open Locked - Pledge allowed, release NOT allowed. Transition allowed.',
  'Freezed - Pledge NOT allowed, release NOT allowed. Transition allowed.',
  'Canceled - Pledge NOT allowed, release allowed for admin. Transition NOT allowed.',
  'Finalized - Pledge NOT allowed, release NOT allowed. Transition NOT allowed.',
];
