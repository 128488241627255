import React from 'react';

import PledgeStats from './PledgeStats';
import PledgeAnalyticsModal from './PledgeAnalyticsModal';
import PledgeTxList from './PledgeTxList';
import PledgeControl from './PledgeControl';

const PledgeTab = ({ deal, pledges }) => {
  return (
    <div className="content pledge-content">
      <PledgeAnalyticsModal deal={deal} />

      <div className="content-row">
        <div className="col col-4 pledge-left transparent p-0">
          <PledgeControl deal={deal} />
          <PledgeStats pledges={pledges} deal={deal} />
        </div>

        <div className="col col-8">
          <div className="pledge-transactions-top">
            <div className="pledge-transactions-title-holder">
              <h3>List of pledges</h3>
            </div>
          </div>
          <PledgeTxList deal={deal} pledges={pledges} />
        </div>
      </div>
    </div>
  );
};
export default PledgeTab;
