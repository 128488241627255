import React from 'react';
import { PoolStatusDescription } from './constants';

export default function PledgeStatusDescription() {
  return (
    <div>
      {PoolStatusDescription.map((description) => {
        const [type, definition] = description.split('-');

        return (
          <div key={type}>
            <span className="tool-status_definition">{type}</span> - {definition}
          </div>
        );
      })}
    </div>
  );
}
