export const getDealStatusName = (statusId) => {
  switch (statusId.toString()) {
    case '1':
      return 'opened';
    case '2':
      return 'paused';
    case '3':
      return 'closed';
    case '4':
      return 'canceled';
    case '5':
      return 'distributed';
    case '7':
      return 'pledge opened';
    case '10':
      return 'pledge paused';
    default:
      throw new Error(`getDealStatusName - Invalid status ${statusId.toString()}`);
  }
};

export const getDealStatusId = (statusName) => {
  switch (statusName) {
    case 'opened':
      return '1';
    case 'paused':
      return '2';
    case 'closed':
      return '3';
    case 'canceled':
      return '4';
    case 'distributed':
      return '5';
    case 'pledge opened':
      return '7';
    case 'pledge paused':
      return '10';
    default:
      throw new Error(`getDealStatusId - Invalid status ${statusName}`);
  }
};
