import React from 'react';
import SvgIcon from 'components/svgIcon';
import moment from 'moment';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { ethers } from 'ethers';

const PledgeTab = ({ deal }) => {
  const { pool, error, isLoading } = usePoolQuery(deal.address);

  return (
    <div className="content pledge-content">
      <div className="content-row">
        <div className="col col-4 pledge-box">
          <div className="pledge-box-image">
            <SvgIcon name="iconPledgeBoxName" />
          </div>
          <div className="pledge-box-info">
            <p className="label">Project name</p>
            <h3 className="value">{deal.name}</h3>
          </div>
        </div>
        <div className="col col-4 pledge-box">
          <div className="pledge-box-image">
            <SvgIcon name="iconPledgeBoxDate" />
          </div>
          <div className="pledge-box-info">
            <p className="label">Pledge date</p>
            <h3 className="value">
              {!!deal.pledgeDate ? moment(deal.pledgeDate).format('D-MMMM-YYYY, HH:mm:ss') : ''}
            </h3>
          </div>
        </div>
        <div className="col col-4 pledge-box">
          <div className="pledge-box-image">
            <SvgIcon name="iconPledgeBoxAmount" />
          </div>
          <div className="pledge-box-info">
            <p className="label">Pledge amount</p>
            <h3 className="value">
              {isLoading
                ? 'Loading...'
                : error
                ? ''
                : `${ethers.utils.formatUnits(pool.totalPledgedAmount, 6)}`}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PledgeTab;
