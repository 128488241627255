import React from 'react';
import Tooltip from 'components/tooltip';
import IconButton from 'components/button/icon-button';

export default function InfoTooltip({ title, placement }) {
  return (
    <Tooltip title={title} placement={placement} className="tooltip-popper">
      <div className="tooltip-btn">
        <IconButton icon="iconInfo" svgWidth={12} />
      </div>
    </Tooltip>
  );
}
