import { useQuery } from 'react-query';
import { useNotifications } from 'hooks';
import { PLEDGE_VAULT_ADDRESS } from 'constants/config';

import { useSelector } from 'react-redux';
import useReadonlyPledgeVault from './useReadonlyPledgeVault';

const usePledgeBalanceQuery = (options = {}) => {
  const walletAddress = useSelector((state) => state.auth.walletAddress);

  const [, showErrorNotification] = useNotifications();

  const pledgeContract = useReadonlyPledgeVault(PLEDGE_VAULT_ADDRESS);

  const {
    data: balance,
    error,
    isFetching,
    refetch,
  } = useQuery(
    ['pledge-balance'],
    async () => {
      const balanceBN = await pledgeContract.balanceOf(walletAddress);

      return balanceBN.toString();
    },
    {
      ...options,
      onError: () => {
        showErrorNotification('Error fetching pledge amount');
      },
    }
  );

  return {
    balance,
    error,
    refetch,
    isLoading: isFetching,
  };
};

export default usePledgeBalanceQuery;
