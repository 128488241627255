import { useMemo } from 'react';
import { ethers } from 'ethers';
import { useSelector } from 'react-redux';
import IERC20 from './abi/IERC20.json';

const useERC20Contract = (address) => {
  const signer = useSelector((state) => state.global.signer);

  return useMemo(() => {
    if (!signer) {
      throw new Error('Signer is not available. Please connect your wallet.');
    }

    return new ethers.Contract(address, IERC20, signer);
  }, [signer, address]);
};

export default useERC20Contract;
