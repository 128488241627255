import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { updateLoading, addNotification, setActiveHashes } from 'store/actions';

import { DEFAULT_CHAIN_NAME, POOL_MANAGER_ADDRESS } from 'constants/config';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import usePoolManager from './usePoolManager';
import { PoolStatus } from '../constants';

const useCreatePool = (dealId, { onSuccess }) => {
  const dispatch = useDispatch();

  const poolManager = usePoolManager(POOL_MANAGER_ADDRESS);

  const { mutateAsync: createPool, isLoading } = useMutation(
    async () => {
      const tx = await poolManager.createPool(dealId, PoolStatus.OpenUnlocked);

      const receipt = await tx.wait();

      const event = receipt.events.find((e) => e.event === 'PoolCreated');

      if (!event) {
        throw new Error('Pool not created');
      }

      return {
        receipt,
        pool: event.args.pool,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt, pool }) => {
        dispatch(updateLoading(false));

        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: () => {
                onSuccess(pool);
              },
              pending: false,
            },
          ])
        );

        dispatch(
          addNotification({
            name: receipt.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          addNotification({
            name: 'Error while creating pool',
            status: 'error',
            statusText: 'Error!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      },
    }
  );

  const checkedCreatePool = useWithCorrectNetwork(createPool);

  return {
    createPool: checkedCreatePool,
    isLoading,
  };
};

export default useCreatePool;
