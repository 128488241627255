import { DEFAULT_CHAIN_ID } from 'constants/config';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useChainSwitcher from './useChainSwitcher';

export default function useWithCorrectNetwork(fn) {
  const chainId = useSelector((state) => state.global.chainId);

  const isCorrectChain = useSelector((state) => state.global.isCorrectChain);

  const switchChain = useChainSwitcher(chainId, DEFAULT_CHAIN_ID);

  return useCallback(
    async (...args) => {
      if (isCorrectChain) {
        return fn(...args);
      }

      return await switchChain(() => fn(...args));
    },
    [fn, isCorrectChain, switchChain]
  );
}
