import React from 'react';
import { Tooltip as MUITooltip } from '@material-ui/core';
import './index.scss';

const Tooltip = ({ children, className = '', ...rest }) => {
  return (
    <MUITooltip
      enterTouchDelay={0}
      classes={{ popper: `custom-tooltip ${className}` }}
      arrow
      {...rest}
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
