import React, { useEffect, useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import NumberInput from 'components/input/number-input';
import './index.scss';
import { roundNumberToDecimals } from 'utils/helpers';
import { Dialog } from '@material-ui/core';
import useReleaseMutation from 'contracts/pledgeVault/hooks/useReleaseMutation';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { ethers } from 'ethers';

const ReleaseModal = ({ poolAddress, onLoad, onCancel, defaultValue, isOpen }) => {
  const [data, setData] = useState({
    amount: defaultValue ? roundNumberToDecimals(Number(defaultValue), 0) : '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const { pool } = usePoolQuery(poolAddress);

  const { release, isLoading } = useReleaseMutation(poolAddress, {
    onSuccess: () => {
      onLoad();
      onCancel();
    },
    onError: onCancel,
  });

  const getErrorMessage = () => {
    if (errorMessage === 'max') {
      const max = ethers.utils.formatUnits(pool.totalPledgedAmount, 'mwei');

      return `Max. = ${max}`;
    }

    return '';
  };

  useEffect(() => {
    if (!pool) return;

    const pledgeInUSDT = ethers.utils.formatUnits(pool.totalPledgedAmount.toString(), 'mwei');

    if (+pledgeInUSDT < data.amount) {
      setErrorMessage('max');
    } else {
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onConfirm = async () => {
    await release(data.amount);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <div className="bd-modal">
        <div className="bd-modal-inner">
          <div className="bd-modal-header">
            <div className="input-holder">
              <NumberInput
                label="Release amount:"
                name="amount"
                value={data.amount.toString()}
                onChange={onChangeData}
                decimalNumber="0"
                error={getErrorMessage()}
              />
            </div>
            <RoundedButton onClick={onCancel}>Cancel</RoundedButton>
            <RoundedButton
              type="secondary"
              onClick={onConfirm}
              disabled={isLoading || data.amount <= 0 || !!errorMessage}
            >
              <div className="d-flex">Confirm</div>
            </RoundedButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ReleaseModal;
