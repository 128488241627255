import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateLoading, setActiveHashes } from 'store/actions';

import { DEFAULT_CHAIN_NAME, PLEDGE_VAULT_ADDRESS } from 'constants/config';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import usePledgeContract from 'contracts/pledgeVault/hooks/usePledgeVault';

const useUpdatePoolStatus = (address, { onSuccess, onError }) => {
  const dispatch = useDispatch();

  const pledgeVault = usePledgeContract(PLEDGE_VAULT_ADDRESS);

  const queryClient = useQueryClient();

  const { mutateAsync: updatePoolStatus, isLoading } = useMutation(
    async (newStatus) => {
      if (!address) {
        throw new Error('Pool not initialized!');
      }

      const tx = await pledgeVault.updatePoolStatus(address, newStatus);

      const receipt = await tx.wait();

      const event = receipt.events.find((e) => e.event === 'PoolStatusUpdated');

      if (!event) {
        throw new Error('Pool status not updated');
      }

      return {
        receipt,
        pool: event.args.pool,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt, pool }) => {
        dispatch(updateLoading(false));
        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: () => {
                queryClient.invalidateQueries(['pool', pool]);
                onSuccess();
              },
              pending: false,
            },
          ])
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        onError();
      },
    }
  );

  const checkedUpdatePoolStatus = useWithCorrectNetwork(updatePoolStatus);

  return {
    updatePoolStatus: checkedUpdatePoolStatus,
    isLoading,
  };
};

export default useUpdatePoolStatus;
