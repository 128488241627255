import { ethers, utils } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import NumberFormat from 'react-number-format';
import { addTokenToMetamask, addChain } from 'contracts/browserWallet';
import { claimOldDeal } from 'contracts/oldDealContract';
import SvgIcon from 'components/svgIcon';
import { setOldDeal, setActiveHashes, addNotification, setSharedNotification } from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { getOldMerkleProof } from 'services/apiService';
import ClaimerArtifact from 'contracts/abis/Claimer.json';
import './index.scss';

const claimerInterface = new ethers.utils.Interface(ClaimerArtifact.abi);

const ClaimOldDealModal = ({ open, oldDeal, onClose }) => {
  const dispatch = useDispatch();
  const [isPending, setPending] = useState(false);
  const [claimNetwork, setClaimNetwork] = useState(false);
  const [activeClaimer, setActiveClaimer] = useState(null);
  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);

  const { chainId, activeHashes } = globalReducer;
  const { accountInfo } = authReducer;

  const addToMetamask = async (activeClaimer) => {
    const networkChecked = await addChain(chainId, activeClaimer.chainId);

    if (networkChecked) {
      setTimeout(() => {
        addTokenToMetamask(
          activeClaimer.token.address,
          activeClaimer.token.symbol,
          activeClaimer.token.decimals
        );
      }, 500);
    }
  };

  const getNameForChainId = (chainId) => {
    if ([1, 4].includes(chainId)) return 'ethereum';
    if ([56, 97].includes(chainId)) return 'bsc';
    if (chainId === 100) return 'xDai';
    if ([43113, 43114].includes(chainId)) return 'avalanche';
    if ([137, 80001].includes(chainId)) return 'polygon';
    if ([8453].includes(chainId)) return 'base';
    if ([4200].includes(chainId)) return 'merlin';
    if ([42161, 421613].includes(chainId)) return 'arbitrum';
  };

  const onClaim = async () => {
    const networkChecked = await addChain(chainId, activeClaimer.chainId);
    if (networkChecked) {
      setPending(true);

      const merkleProof = await getOldMerkleProof(activeClaimer.merkleRoot);
      const tx = await claimOldDeal(
        activeClaimer.address,
        activeClaimer.token.address,
        merkleProof,
        accountInfo.address
      );
      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.hash,
              pending: false,
              chain: getNameForChainId(activeClaimer.chainId),
              callback: async (transaction) => {
                const log = claimerInterface.parseLog(transaction.logs[1]);

                activeClaimer.claimable = activeClaimer.claimable.sub(log.args.amount);

                dispatch(setOldDeal({ ...oldDeal }));
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.hash,
            chain: getNameForChainId(activeClaimer.chainId),
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      } else {
        dispatch(
          setSharedNotification({
            status: 'error',
            title: 'Error',
            description: 'Something went wrong',
          })
        );
      }
      setPending(false);
      onClose();
    } else {
      dispatch(
        setSharedNotification({
          status: 'error',
          title: 'Error',
          description: `You need to change your network to "${getNameForChainId(
            activeClaimer.chainId
          )}" to continue.`,
        })
      );
    }
  };

  useEffect(() => {
    if (!claimNetwork) {
      setClaimNetwork(
        getNameForChainId(oldDeal.claimers.filter((c) => Number(c.claimable) > 0)[0].chainId)
      );
      return;
    }
    setActiveClaimer(
      claimNetwork
        ? oldDeal.claimers.find((c) => getNameForChainId(c.chainId) === claimNetwork)
        : oldDeal.claimers.find((c) => Number(c.claimable) > 0)
    );
  }, [claimNetwork, oldDeal.claimers]);

  const parseChainNameCasing = (chainName) => {
    if (chainName.toLowerCase() === 'xdai') return 'xDai';
    if (chainName.toLowerCase() === 'bsc') return 'BSC';
    return chainName.charAt(0).toUpperCase() + chainName.slice(1);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="claim-old-deal-modal">
        <div className="claim-old-deal-modal__head">
          <div>
            <RoundedAvatar src={oldDeal.imageUrl} />
            <span>{oldDeal.name}</span>
          </div>
          <div>
            <RoundedButton disabled={isPending} onClick={onClose}>
              Cancel
            </RoundedButton>
            <RoundedButton type="secondary" disabled={isPending} onClick={onClaim}>
              Claim
            </RoundedButton>
          </div>
        </div>
        <Divider />
        <div className="claim-old-deal-modal__claimers">
          {oldDeal.claimers
            .filter((c) => Number(c.claimable) > 0)
            .map((claimer) => (
              <button
                key={claimer.chainId}
                type="button"
                className={claimNetwork === getNameForChainId(claimer.chainId) ? 'active' : ''}
                onClick={() => setClaimNetwork(getNameForChainId(claimer.chainId))}
              >
                {parseChainNameCasing(getNameForChainId(claimer.chainId))}
              </button>
            ))}
        </div>
        {activeClaimer && (
          <div className="claim-old-deal-modal__table">
            <div className="claim-old-deal-modal__table-head">
              <span>Token</span>
              <span>Balance</span>
              <span>Add Token</span>
            </div>
            <div className="claim-old-deal-modal__table-body">
              <span>{activeClaimer.token.name}</span>
              <NumberFormat
                value={utils.formatUnits(activeClaimer.claimable, activeClaimer.token.decimals)}
                decimalScale={2}
                thousandSeparator
                displayType="text"
              />
              <button
                className="metamask-button"
                type="button"
                onClick={() => addToMetamask(activeClaimer)}
              >
                <SvgIcon name="metamaskFox" />
              </button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

ClaimOldDealModal.propTypes = {
  open: PropTypes.bool,
  isPending: PropTypes.bool,
  deal: PropTypes.shape(),
  onClose: PropTypes.func,
};

ClaimOldDealModal.defaultProps = {
  open: false,
  isPending: false,
  deal: {},
  onClose: () => {},
};

export default ClaimOldDealModal;
