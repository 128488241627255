import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import { updateRequiredChain } from 'services/apiService';
import { setUserDeal } from 'store/actions';

import { addStringAmounts, thousandSeparator, roundNumberToDecimals } from 'utils/helpers';
import RoundedButton from 'components/button/rounded-button';
import ChainList from 'contracts/json/ChainList.json';
import CustomSelect from 'components/select/custom-select';
import { useDispatch } from 'react-redux';
import { useNotifications } from 'hooks';

const PledgeStats = ({ pledges, deal }) => {
  const dispatch = useDispatch();
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const [selectedChain, setSelectedChain] = useState(deal?.requiredChain || 'Unset');

  const onConfirm = async () => {
    try {
      const chain = selectedChain === 'Unset' ? undefined : selectedChain;

      await updateRequiredChain({ id: deal.id, chain });

      const newDeal = { ...deal, requiredChain: chain };

      dispatch(setUserDeal(newDeal));
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <>
      <div className="col col-12 pledge-box">
        <div className="pledge-box-image">
          <SvgIcon name="iconLogoGreen" />
        </div>
        <div className="pledge-box-info">
          <p className="label">Total amount</p>
          {pledges && (
            <h3 className="value">
              ${' '}
              {thousandSeparator(
                roundNumberToDecimals(
                  Number(
                    pledges.map((p) => p.amount).reduce((a, b) => addStringAmounts(a, b, 6), '0.0')
                  ),
                  0
                )
              )}
            </h3>
          )}
        </div>
      </div>

      <div className="col col-12 pledge-box">
        <div className="pledge-box-info">
          <p className="label">Total count</p>
          <h3 className="value">{pledges?.length}</h3>
        </div>
      </div>

      <div className="col col-12 pledge-box">
        <div className="pledge-box-info">
          <p className="label">Chain select</p>
          <CustomSelect
            name="chain"
            value={selectedChain}
            items={[{ value: 'Unset' }, ...ChainList]}
            onSelect={(name, value) => setSelectedChain(value)}
            absolute
          />
          <Box mt={1} display="flex" justifyContent="flex-end">
            <RoundedButton
              onClick={onConfirm}
              type="primary"
              disabled={
                deal.requiredChain === selectedChain ||
                (!deal.requiredChain && selectedChain === 'Unset')
              }
            >
              Save
            </RoundedButton>
          </Box>
        </div>
      </div>
    </>
  );
};

export default PledgeStats;
