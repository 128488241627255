import React, { useState } from 'react';
import './index.scss';
import useUpdatePoolStatus from 'contracts/poolManager/hooks/useUpdatePoolStatus';
import { useNotifications } from 'hooks';
import CustomSelect from 'components/select/custom-select';
import RoundedButton from 'components/button/rounded-button';
import { PoolStatus } from 'contracts/poolManager/constants';
import { AllPoolActions, ValidPoolActions } from '../PledgeControl/constants';

const PoolActionsDropdown = ({ pool }) => {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const [selectedStatus, setSelectedStatus] = useState('Unset');

  const { updatePoolStatus, isLoading: isUpdating } = useUpdatePoolStatus(pool.address, {
    onSuccess: () => {
      showSuccessNotification(`Pool status update successfully`);
    },
    onError: () => {
      showErrorNotification(`Error while updating pool status`);
    },
  });

  if (!pool || pool.status === PoolStatus.Canceled) return null;

  return (
    <div className="pool-actions">
      <CustomSelect
        className="pool-actions"
        value={selectedStatus}
        items={[
          { value: 'Unset' },
          ...AllPoolActions.map(({ label, status }) => ({
            name: label,
            value: status,
          })),
        ]}
        onSelect={(_, status) => setSelectedStatus(status)}
        absolute
      />
      <RoundedButton
        disabled={
          isNaN(selectedStatus) ||
          isUpdating ||
          !ValidPoolActions[pool.status].includes(selectedStatus)
        }
        type="secondary"
        onClick={() => updatePoolStatus(selectedStatus)}
      >
        Update
      </RoundedButton>
    </div>
  );
};

export default PoolActionsDropdown;
