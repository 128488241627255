import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Web3Modal from 'web3modal';
import {
  updateAuth,
  setChainId,
  resetGlobal,
  resetAuth,
  setWeb3Provider,
  setWeb3Signer,
} from 'store/actions';
import { ethers } from 'ethers';

const useWeb3Modal = () => {
  const [provider, setProvider] = useState(null);
  const dispatch = useDispatch();

  const web3Modal = useMemo(
    () =>
      new Web3Modal({
        cacheProvider: true,
      }),
    []
  );

  const clearState = useCallback(() => {
    localStorage.clear();
    dispatch(resetGlobal());
    dispatch(resetAuth());
  }, [dispatch]);

  const logoutOfWeb3Modal = useCallback(() => {
    web3Modal.clearCachedProvider();
    if (provider) provider.removeAllListeners();
    clearState();
    setProvider(null);
  }, [web3Modal, provider, clearState]);

  const getWeb3Accounts = useCallback(async () => {
    // if the user is logged out of metamask
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    if (accounts.length === 0) {
      logoutOfWeb3Modal();
      return;
    }

    const isPreviewMode = localStorage.getItem('isPreviewMode');
    const tokenWallet = localStorage.getItem('token_wallet');
    const adminWallet = localStorage.getItem('adminWallet');

    // if the user was in preview mode, they should be logged in with their admin wallet
    // otherwise, they shioule be logged in with their own wallet
    const loggedInUserWallet = isPreviewMode ? tokenWallet : adminWallet;
    if (loggedInUserWallet && loggedInUserWallet.toLowerCase() !== accounts[0].toLowerCase()) {
      logoutOfWeb3Modal();
      return;
    }

    // if the user was in preview mode, we are going to set the token wallet
    // otherwise we're are going to set the metmask wallet
    const viewingUserWallet = isPreviewMode ? tokenWallet : accounts[0];
    if (accounts.length > 0) {
      dispatch(updateAuth({ walletAddress: viewingUserWallet }));
    }
  }, [dispatch, logoutOfWeb3Modal]);

  const getNetwork = useCallback(
    async (web3) => {
      const network = await web3.getNetwork();

      const chainId = network.chainId.toString();

      dispatch(setWeb3Provider({ provider: web3, chainId }));
    },
    [dispatch]
  );

  // Open wallet selection modal.
  const loadWeb3Modal = useCallback(async () => {
    const newProvider = await web3Modal.connect();
    setProvider(newProvider);
  }, [web3Modal]);

  useEffect(() => {
    if (provider) {
      const web3 = new ethers.providers.Web3Provider(provider, 'any');
      const signer = web3.getSigner();

      getNetwork(web3);
      dispatch(setWeb3Signer(signer));

      getWeb3Accounts();
      provider.on('accountsChanged', (_accounts) => {
        clearState();
        dispatch(updateAuth({ walletAddress: _accounts[0] }));
      });

      provider.on('chainChanged', (chainId) => {
        dispatch(setChainId(chainId));
      });

      return () => {
        provider.removeAllListeners();
      };
    }
  }, [provider, dispatch, getWeb3Accounts, clearState, getNetwork]);

  return { provider, loadWeb3Modal, logoutOfWeb3Modal };
};

export default useWeb3Modal;
