export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;

export const DEFAULT_CHAIN_ID = process.env.REACT_APP_DEFAULT_CHAIN_ID;
export const DEFAULT_CHAIN_NAME = process.env.REACT_APP_DEFAULT_CHAIN_NAME;

// TOKENS
export const BDT_ADDRESS = process.env.REACT_APP_BDT_ADDRESS;
export const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS;
export const XUSDT_ADDRESS = process.env.REACT_APP_XUSDT_ADDRESS;

// APP CONTRACTS
export const LOCK_ADDRESS = process.env.REACT_APP_LOCK_ADDRESS;
export const TRANSFER_PROXY_ADDRESS = process.env.REACT_APP_TRANSFER_PROXY_ADDRESS;
export const DEAL_FACTORY_ADDRESS = process.env.REACT_APP_DEAL_FACTORY_ADDRESS;
export const PLEDGE_VAULT_ADDRESS = process.env.REACT_APP_PLEDGE_VAULT_ADDRESS;
export const POOL_MANAGER_ADDRESS = process.env.REACT_APP_POOL_MANAGER_ADDRESS;

// APP CLAIMER CONTRACTS
export const ETH_CLAIMER_FACTORY_ADDRESS = process.env.REACT_APP_ETH_CLAIMER_FACTORY_ADDRESS;
export const BSC_CLAIMER_FACTORY_ADDRESS = process.env.REACT_APP_BSC_CLAIMER_FACTORY_ADDRESS;
export const POLYGON_CLAIMER_FACTORY_ADDRESS =
  process.env.REACT_APP_POLYGON_CLAIMER_FACTORY_ADDRESS;
export const AVALANCHE_CLAIMER_FACTORY_ADDRESS =
  process.env.REACT_APP_AVALANCHE_CLAIMER_FACTORY_ADDRESS;
export const BASE_CLAIMER_FACTORY_ADDRESS = process.env.REACT_APP_BASE_CLAIMER_FACTORY_ADDRESS;
export const MERLIN_CLAIMER_FACTORY_ADDRESS = process.env.REACT_APP_MERLIN_CLAIMER_FACTORY_ADDRESS;
export const ARBITRUM_CLAIMER_FACTORY_ADDRESS =
  process.env.REACT_APP_ARBITRUM_CLAIMER_FACTORY_ADDRESS;

// CHAIN CONFIGURATIONS
export const ETHEREUM_PROVIDER_URL = process.env.REACT_APP_ETHEREUM_PROVIDER_URL;
export const ETHEREUM_CHAIN_ID = +process.env.REACT_APP_ETHEREUM_CHAIN_ID;
export const ETHEREUM_CHAIN_NAME = process.env.REACT_APP_ETHEREUM_NAME;
export const ETHERSCAN_ADDRESS_LINK = process.env.REACT_APP_ETHERSCAN_ADDRESS_LINK;
export const ETHERSCAN_TX_LINK = process.env.REACT_APP_ETHERSCAN_TX_LINK;
export const MULTICALL_ADDRESS = process.env.REACT_APP_MULTICALL_ADDRESS;
export const MULTICALL_UNSUPPORTED_CHAIN_IDS =
  process.env.REACT_APP_MULTICALL_UNSUPPORTED_CHAIN_IDS?.split(',') || [];

export const XDAI_PROVIDER_URL = process.env.REACT_APP_XDAI_PROVIDER_URL;
export const XDAI_CHAIN_ID = +process.env.REACT_APP_XDAI_CHAIN_ID;
export const GNOSISSCAN_ADDRESS_LINK = process.env.REACT_APP_GNOSISSCAN_ADDRESS_LINK;
export const GNOSISSCAN_TX_LINK = process.env.REACT_APP_GNOSISSCAN_TX_LINK;

export const BSC_PROVIDER_URL = process.env.REACT_APP_BSC_PROVIDER_URL;
export const BSC_CHAIN_ID = +process.env.REACT_APP_BSC_CHAIN_ID;
export const BSC_CHAIN_NAME = process.env.REACT_APP_BSC_NAME;
export const BSCSCAN_ADDRESS_LINK = process.env.REACT_APP_BSC_ADDRESS_LINK;
export const BSCSCAN_TX_LINK = process.env.REACT_APP_BSC_TX_LINK;

export const POLYGON_PROVIDER_URL = process.env.REACT_APP_POLYGON_PROVIDER_URL;
export const POLYGON_CHAIN_ID = +process.env.REACT_APP_POLYGON_CHAIN_ID;
export const POLYGON_CHAIN_NAME = process.env.REACT_APP_POLYGON_NAME;
export const POLYGONSCAN_ADDRESS_LINK = process.env.REACT_APP_POLYGONSCAN_ADDRESS_LINK;
export const POLYGONSCAN_TX_LINK = process.env.REACT_APP_POLYGONSCAN_TX_LINK;

export const AVALANCHE_PROVIDER_URL = process.env.REACT_APP_AVALANCHE_PROVIDER_URL;
export const AVALANCHE_CHAIN_ID = +process.env.REACT_APP_AVALANCHE_CHAIN_ID;
export const AVALANCHE_CHAIN_NAME = process.env.REACT_APP_AVALANCHE_NAME;
export const SNOWTRACE_ADDRESS_LINK = process.env.REACT_APP_SNOWTRACE_ADDRESS_LINK;
export const SNOWTRACE_TX_LINK = process.env.REACT_APP_SNOWTRACE_TX_LINK;

export const BASE_PROVIDER_URL = process.env.REACT_APP_BASE_PROVIDER_URL;
export const BASE_CHAIN_ID = +process.env.REACT_APP_BASE_CHAIN_ID;
export const BASE_CHAIN_NAME = process.env.REACT_APP_BASE_NAME;
export const BASE_ADDRESS_LINK = process.env.REACT_APP_BASE_ADDRESS_LINK;
export const BASE_TX_LINK = process.env.REACT_APP_BASE_TX_LINK;

export const MERLIN_PROVIDER_URL = process.env.REACT_APP_MERLIN_PROVIDER_URL;
export const MERLIN_CHAIN_ID = +process.env.REACT_APP_MERLIN_CHAIN_ID;
export const MERLIN_CHAIN_NAME = process.env.REACT_APP_MERLIN_NAME;
export const MERLIN_ADDRESS_LINK = process.env.REACT_APP_MERLIN_ADDRESS_LINK;
export const MERLIN_TX_LINK = process.env.REACT_APP_MERLIN_TX_LINK;

export const ARBITRUM_PROVIDER_URL = process.env.REACT_APP_ARBITRUM_PROVIDER_URL;
export const ARBITRUM_WS_PROVIDER_URL = process.env.REACT_APP_ARBITRUM_WS_PROVIDER_URL;
export const ARBITRUM_CHAIN_ID = +process.env.REACT_APP_ARBITRUM_CHAIN_ID;
export const ARBITRUM_CHAIN_NAME = process.env.REACT_APP_ARBITRUM_NAME;
export const ARBISCAN_ADDRESS_LINK = process.env.REACT_APP_ARBISCAN_ADDRESS_LINK;
export const ARBISCAN_TX_LINK = process.env.REACT_APP_ARBISCAN_TX_LINK;

// KYC
export const KYC_URL = process.env.REACT_APP_KYC_URL;
export const KYC_CLIENT_ID = process.env.REACT_APP_KYC_CLIENT_ID;
export const KYC_REDIRECT_URL = process.env.REACT_APP_KYC_REDIRECT_URL;

// FIREBASE
export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASURMENT_ID = process.env.REACT_APP_FIREBASE_MEASURMENT_ID;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;

export const SUPPORTED_NETWORKS = [
  {
    chainId: process.env.REACT_APP_ETHEREUM_CHAIN_ID,
    name: process.env.REACT_APP_ETHEREUM_NAME,
    rpcUrl: process.env.REACT_APP_ETHEREUM_PROVIDER_URL,
    explorerAddressLink: process.env.REACT_APP_ETHERSCAN_ADDRESS_LINK,
    explorerTxLink: process.env.REACT_APP_ETHERSCAN_TX_LINK,
  },
  {
    chainId: process.env.REACT_APP_POLYGON_CHAIN_ID,
    name: process.env.REACT_APP_POLYGON_NAME,
    rpcUrl: process.env.REACT_APP_POLYGON_PROVIDER_URL,
    explorerAddressLink: process.env.REACT_APP_POLYGONSCAN_ADDRESS_LINK,
    explorerTxLink: process.env.REACT_APP_POLYGONSCAN_TX_LINK,
  },
  {
    chainId: process.env.REACT_APP_BSC_CHAIN_ID,
    name: process.env.REACT_APP_BSC_NAME,
    rpcUrl: process.env.REACT_APP_BSC_PROVIDER_URL,
    explorerAddressLink: process.env.REACT_APP_BSC_ADDRESS_LINK,
    explorerTxLink: process.env.REACT_APP_BSC_TX_LINK,
  },
  {
    chainId: process.env.REACT_APP_XDAI_CHAIN_ID,
    name: process.env.REACT_APP_XDAI_NAME,
    rpcUrl: process.env.REACT_APP_XDAI_PROVIDER_URL,
    explorerAddressLink: process.env.REACT_APP_BLOCKSCOUT_ADDRESS_LINK,
    explorerTxLink: process.env.REACT_APP_BLOCKSCOUT_TX_LINK,
  },
  {
    chainId: process.env.REACT_APP_AVALANCHE_CHAIN_ID,
    name: process.env.REACT_APP_AVALANCHE_NAME,
    rpcUrl: process.env.REACT_APP_AVALANCHE_PROVIDER_URL,
    explorerAddressLink: process.env.REACT_APP_SNOWTRACE_ADDRESS_LINK,
    explorerTxLink: process.env.REACT_APP_SNOWTRACE_TX_LINK,
  },
  {
    chainId: process.env.REACT_APP_BASE_CHAIN_ID,
    name: process.env.REACT_APP_BASE_NAME,
    rpcUrl: process.env.REACT_APP_BASE_PROVIDER_URL,
    explorerAddressLink: process.env.REACT_APP_BASE_ADDRESS_LINK,
    explorerTxLink: process.env.REACT_APP_BASE_TX_LINK,
  },
  {
    chainId: process.env.REACT_APP_MERLIN_CHAIN_ID,
    name: process.env.REACT_APP_MERLIN_NAME,
    rpcUrl: process.env.REACT_APP_MERLIN_PROVIDER_URL,
    explorerAddressLink: process.env.REACT_APP_MERLIN_ADDRESS_LINK,
    explorerTxLink: process.env.REACT_APP_MERLIN_TX_LINK,
  },
  {
    chainId: process.env.REACT_APP_ARBITRUM_CHAIN_ID,
    name: process.env.REACT_APP_ARBITRUM_NAME,
    rpcUrl: process.env.REACT_APP_ARBITRUM_PROVIDER_URL,
    explorerAddressLink: process.env.REACT_APP_ARBISCAN_ADDRESS_LINK,
    explorerTxLink: process.env.REACT_APP_ARBISCAN_TX_LINK,
  },
];
