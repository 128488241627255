import { ethers } from 'ethers';
import { useMemo } from 'react';
import { getProviderByChainId } from 'contracts/providers';
import { DEFAULT_CHAIN_ID } from 'constants/config';
import IPledgeVault from '../abi/IPledgeVault.json';

const useReadonlyPledgeVault = (address) => {
  return useMemo(() => {
    if (!address) return;

    const provider = getProviderByChainId(DEFAULT_CHAIN_ID);

    if (!provider) {
      throw Error('Provider missing');
    }

    return new ethers.Contract(address, IPledgeVault, provider);
  }, [address]);
};

export default useReadonlyPledgeVault;
