import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'components/svgIcon';
import './index.scss';

function IconButton({ children, icon, onClick, pending, disabled, className, svgWidth }) {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div className={`icon-btn vertical-center${pending ? ' pending' : ''} ${className}`}>
      <button
        className={disabled ? 'disabled' : ''}
        disabled={disabled}
        type="button"
        onClick={handleClick}
      >
        <SvgIcon name={icon} width={svgWidth} />
        {children}
      </button>
    </div>
  );
}

IconButton.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  icon: '',
  onClick: () => {},
};

export default IconButton;
