import React, { useEffect, useRef, useState } from 'react';

import { CSVLink } from 'react-csv';
import BasicAnalyticsPickerModal from 'features/deals/BasicAnalyticsPickerModal';
import { getPledgeAnalytics } from 'services/apiService';

const PledgeAnalyticsModal = ({ deal }) => {
  const [pledgeAnalytics, setPledgeAnalytics] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const pledgeAnalyticsRef = useRef();
  const [pledgeAnalyticsToExport, setPledgeAnalyticsToExport] = useState([]);

  useEffect(() => {
    getPledgeAnalytics(deal.id).then((res) => {
      setPledgeAnalytics(res);
    });
  }, [deal]);

  const onAnalyticsDownload = (choosenFields) => {
    const { result } = pledgeAnalytics.reduce(
      (accum, item, i) => {
        let { choosenIndexes } = accum;

        if (i === 0) {
          choosenIndexes = choosenFields.map(({ value }) => item.indexOf(value));
        }

        return {
          choosenIndexes,
          result: [...accum.result, item.filter((_, i) => choosenIndexes.includes(i))],
        };
      },
      { choosenIndexes: [], result: [] }
    );

    setPledgeAnalyticsToExport(result);
    setTimeout(() => pledgeAnalyticsRef.current.link.click(), 0);
    setIsOpen(false);
  };

  return (
    <>
      {pledgeAnalytics && (
        <CSVLink
          ref={pledgeAnalyticsRef}
          data={pledgeAnalyticsToExport}
          style={{ display: 'none' }}
          filename={`pledges_${deal.name}.csv`}
        >
          Export basic analytics to CSV
        </CSVLink>
      )}
      <BasicAnalyticsPickerModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOk={onAnalyticsDownload}
        options={pledgeAnalytics?.[0] || []}
      />
    </>
  );
};

export default PledgeAnalyticsModal;
