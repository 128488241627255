import React, { useState } from 'react';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { PoolStatus } from 'contracts/poolManager/constants';
import InfoTooltip from 'components/tooltip/custom/InfoTooltip';
import { PoolLabel } from './constants';
import PoolCreateButton from '../PoolCreateButton';
import PoolActionsDropdown from '../PoolActionsDropdown';
import './index.scss';
import PledgeStatusDescription from './PledgeStatusDescription';

export const PoolLabelColor = {
  [PoolStatus.NotActive]: 'white',
  [PoolStatus.OpenUnlocked]: 'green',
  [PoolStatus.OpenLocked]: 'green',
  [PoolStatus.Freezed]: 'white',
  [PoolStatus.Canceled]: 'red',
};

const PledgeControl = ({ deal }) => {
  // TODO -> workaround solution before introducing react query
  const [poolAddress, setPoolAddress] = useState(deal.address);

  const { pool, isLoading: isPoolLoading } = usePoolQuery(poolAddress);

  return (
    <div className="col col-12 pledge-control">
      <div className="horizontal-flex horizontal-flex-center">
        <p>Pledge Control</p>
        <InfoTooltip title={<PledgeStatusDescription />} placement="right-start" />
      </div>

      {isPoolLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="vertical-flex pledge-control-container">
          <h2 className={`${PoolLabelColor[pool?.status]} mb-10`}>
            {PoolLabel[pool?.status || 0].toUpperCase()}
          </h2>
          <div className="horizontal-flex justify-space-between">
            {!poolAddress ? (
              <PoolCreateButton dealId={deal.id} onCreate={(address) => setPoolAddress(address)} />
            ) : (
              <PoolActionsDropdown pool={{ ...pool, address: poolAddress }} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PledgeControl;
