import React from 'react';
import RoundedButton from 'components/button/rounded-button';

import useCreatePool from 'contracts/poolManager/hooks/useCreatePool';

const PoolCreateButton = ({ dealId, onCreate }) => {
  const { createPool, isLoading } = useCreatePool(dealId, {
    onSuccess: onCreate,
  });

  return (
    <RoundedButton type="secondary" disabled={isLoading} onClick={() => createPool()}>
      Open
    </RoundedButton>
  );
};

export default PoolCreateButton;
