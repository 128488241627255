import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Divider } from '@material-ui/core';
import './index.scss';
import SvgIcon from 'components/svgIcon';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import RoundedButton from 'components/button/rounded-button';
import { ARBISCAN_TX_LINK } from 'constants/config';
import { shortenAddress } from 'utils';
import useTxMetadataQuery from './hooks/useTxMetadataQuery';

const PledgeTxListModal = ({ open, deal, onClose }) => {
  const { transactions } = useTxMetadataQuery(deal.id, { enabled: open });

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <div className="claim-deal-check-modal">
        <div className="claim-deal-check-modal__head">
          <div>
            <RoundedAvatar src={deal.imageUrl} />

            <div>
              <span>{deal.name}</span>
              {deal.dealSize && deal.raisedAmount && (
                <CustomProgressBar
                  total={Number(deal.dealSize)}
                  value={Number(deal.raisedAmount)}
                />
              )}
            </div>
          </div>
          <RoundedButton onClick={onClose}>Close</RoundedButton>
        </div>
        <Divider />
        <div className="claim-deal-check-modal__table">
          <div className="claim-deal-check-modal__table-head">
            <span>Wallet</span>
            <span>TX hash</span>
            <span>Action</span>
            <span className="small-column">TX link</span>
          </div>
          <div className="claim-deal-check-modal__table-body">
            {transactions?.map((transaction) => {
              return (
                <div key={transaction.id}>
                  <span>{shortenAddress(transaction.user.wallet)}</span>
                  <span>{shortenAddress(transaction.transactionHash)}</span>
                  <span>{transaction.pledgeActionType}</span>
                  <span className="small-column">
                    <a
                      href={`${ARBISCAN_TX_LINK}${transaction.transactionHash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SvgIcon name="iconExternalLink" />
                    </a>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PledgeTxListModal;
