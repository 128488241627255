import { useSelector } from 'react-redux';
import { ethers } from 'ethers';
import { useMemo } from 'react';
import IPledgeVault from '../abi/IPledgeVault.json';

const usePledgeVault = (address) => {
  const signer = useSelector((state) => state.global.signer);

  return useMemo(() => {
    if (!address) return;

    if (!signer) {
      throw new Error('Signer is not available. Please connect your wallet.');
    }

    return new ethers.Contract(address, IPledgeVault, signer);
  }, [address, signer]);
};

export default usePledgeVault;
