import { Dialog, Divider, Radio, Step, StepLabel, Stepper } from '@material-ui/core';
import RoundedButton from 'components/button/rounded-button';
import CustomInput from 'components/input/custom-input';
import CustomSelect from 'components/select/custom-select';
import SvgIcon from 'components/svgIcon';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

const pollTypes = [
  { id: 0, name: 'General', value: 'GENERAL' },
  { id: 1, name: 'Deal', value: 'DEAL' },
  { id: 2, name: 'Pledge', value: 'PLEDGE' },
];

const votingTypes = [
  { id: 0, name: 'BDT Held', value: 'BDT_HELD' },
  { id: 1, name: 'Contribution', value: 'CONTRIBUTION' },
  { id: 2, name: 'Per account', value: 'PER_ACCOUNT' },
];

const AddEditPollModal = ({ open, onClose, onOk, poll }) => {
  const [pollToSave, setPollToSave] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const globalReducer = useSelector((state) => state.global);
  const [dealSearch, setDealSearch] = useState('');
  const { userDeals } = globalReducer;

  useEffect(() => {
    setPollToSave({
      pollType: pollTypes[0].value,
      votingType: votingTypes[0].value,
      dateStart: new Date(),
      dateEnd: new Date(),
      options: [{}, {}],
      ...poll,
    });
    setCurrentStep(0);
  }, [poll]);

  const doesNeedDealSelect = pollTypes[0].value !== pollToSave?.pollType;

  const numberOfSteps = doesNeedDealSelect ? 3 : 2;

  const isLastStep = currentStep === numberOfSteps - 1;

  const finishStep = () => {
    if (isLastStep) {
      onOk(pollToSave);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handleChange = (name, value) => {
    setPollToSave((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddOption = () => {
    setPollToSave((prev) => ({ ...prev, options: [...prev.options, { label: '' }] }));
  };

  const isContinueDisabled = useMemo(() => {
    if (!pollToSave) return true;
    if (currentStep === 0) {
      return (
        !pollToSave.dateStart ||
        !pollToSave.dateEnd ||
        !pollToSave.pollType ||
        !pollToSave.votingType
      );
    }
    if (currentStep === 1 && doesNeedDealSelect) {
      return !pollToSave.dealId;
    }
    if (isLastStep) {
      return (
        pollToSave.options.length < 2 ||
        pollToSave.options.some((option) => !option.label) ||
        !pollToSave.title
      );
    }
    return true;
  }, [pollToSave, currentStep, doesNeedDealSelect, isLastStep]);

  if (!poll || !pollToSave) return null;

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: 'poll-modal__container' }}>
      <div className="poll-modal">
        <div className="poll-modal__head">
          <CustomInput
            name="title"
            value={pollToSave.title}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            placeholder="Name"
          />
          <div>
            <RoundedButton type="transparent" onClick={onClose}>
              Cancel
            </RoundedButton>
            <RoundedButton type="primary" onClick={finishStep} disabled={isContinueDisabled}>
              {isLastStep ? 'Save' : 'Continue'}
            </RoundedButton>
          </div>
        </div>
        <Divider />
        <div className="poll-modal__body">
          <div>
            <Stepper activeStep={currentStep} classes={{ root: 'poll-modal__stepper' }}>
              <Step key="details">
                <StepLabel>Poll details</StepLabel>
              </Step>
              {doesNeedDealSelect && (
                <Step key="deal/pledges">
                  <StepLabel>Deals/Pledges</StepLabel>
                </Step>
              )}
              <Step key="questions">
                <StepLabel>Question</StepLabel>
              </Step>
            </Stepper>
            {currentStep === 0 && (
              <>
                <div className="dropdown-section">
                  <CustomSelect
                    label="Poll type"
                    name="pollType"
                    value={pollToSave.pollType}
                    items={pollTypes}
                    onSelect={handleChange}
                    absolute
                  />
                  <CustomSelect
                    label="Voting Type"
                    name="votingType"
                    value={pollToSave.votingType}
                    items={votingTypes}
                    onSelect={handleChange}
                    absolute
                  />
                </div>
                <div className="dropdown-section">
                  <div>
                    <span className="date-picker">Start Date</span>
                    <DatePicker
                      selected={pollToSave.dateStart}
                      showTimeSelect
                      onChange={(date) => handleChange('dateStart', date)}
                      dateFormat="d MMMM yyyy, HH:mm"
                    />
                  </div>
                  <div>
                    <span className="date-picker">End Date</span>
                    <DatePicker
                      selected={pollToSave.dateEnd}
                      showTimeSelect
                      onChange={(date) => handleChange('dateEnd', date)}
                      dateFormat="d MMMM yyyy, HH:mm"
                    />
                  </div>
                </div>
              </>
            )}
            {currentStep === 1 && doesNeedDealSelect && (
              <div className="deal-picker">
                <div className="deal-picker__search">
                  <div>
                    <SvgIcon name="search" />
                  </div>
                  <input
                    type="text"
                    value={dealSearch}
                    placeholder="Search"
                    onChange={(e) => setDealSearch(e.target.value)}
                  />
                </div>
                <div className="deal-picker__items">
                  {userDeals
                    .filter(({ name, statusId }) =>
                      name.toLowerCase().includes(dealSearch.toLowerCase()) &&
                      pollToSave.pollType === pollTypes[1].value
                        ? [1, 2, 3, 4, 5].includes(statusId)
                        : [7, 8, 10].includes(statusId)
                    )
                    .slice(0, 6)
                    .map(({ name, id }) => (
                      <div key={id} onClick={() => handleChange('dealId', id)}>
                        <Radio checked={+pollToSave.dealId === +id} />
                        {name}
                      </div>
                    ))}
                </div>
              </div>
            )}
            {isLastStep && (
              <>
                <div className="question-wrapper">
                  <div>
                    <span>Poll question & description</span>
                    <div>
                      <button
                        type="button"
                        onClick={() => navigator.clipboard.writeText(pollToSave.pollInfo)}
                      >
                        <SvgIcon name="iconCopy" width={20} height={20} />
                      </button>
                      <button
                        type="button"
                        onClick={() => setPollToSave((prev) => ({ ...prev, pollInfo: '' }))}
                      >
                        <SvgIcon name="iconErase" width={20} height={20} />
                      </button>
                    </div>
                  </div>
                  <textarea
                    rows="3"
                    name="pollInfo"
                    value={pollToSave.pollInfo || ''}
                    onFocus={(e) => {
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                    onBlur={(e) => {
                      e.target.style.height = `92px`;
                    }}
                    onChange={(e) => {
                      e.target.style.height = `${e.target.scrollHeight}px`;
                      handleChange(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <div className="choices-wrapper">
                  <span>Choices</span>
                  {pollToSave.options.map(({ id, label }, i) => (
                    <div key={id || i}>
                      <CustomInput
                        name="description"
                        value={label}
                        onChange={(e) =>
                          setPollToSave((prev) => ({
                            ...prev,
                            options: prev.options.map((option, index) =>
                              index === i ? { ...option, label: e.target.value } : option
                            ),
                          }))
                        }
                      />
                      {!id && (
                        <button
                          type="button"
                          onClick={() =>
                            setPollToSave((prev) => ({
                              ...prev,
                              options: prev.options.filter((_, index) => index !== i),
                            }))
                          }
                        >
                          <SvgIcon name="iconErase" width={20} height={20} />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                <div className="add-option-wrapper" onClick={handleAddOption}>
                  <div>
                    <SvgIcon name="plus" width={16} height={16} />
                  </div>
                  <span>Add another choice</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddEditPollModal;
