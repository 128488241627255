import * as actionTypes from '../constants';

export const initGlobal = (payload) => ({
  type: actionTypes.ACTION_INIT_GLOBAL,
  payload,
});

export const updateGlobal = (payload) => ({
  type: actionTypes.ACTION_UPDATE_GLOBAL,
  payload,
});

export const updateSystemPart = (payload) => ({
  type: actionTypes.ACTION_UPDATE_SYSTEM_PART,
  payload,
});

export const updateLoading = (payload) => ({
  type: actionTypes.ACTION_UPDATE_LOADING,
  payload,
});

export const updateFetchingDeals = (payload) => ({
  type: actionTypes.ACTION_UPDATE_FETCHING_DEALS,
  payload,
});

export const setSharedNotification = (payload) => ({
  type: actionTypes.ACTION_SET_SHARED_NOTIFICATION,
  payload,
});

export const setAlertNotification = (payload) => ({
  type: actionTypes.ACTION_SET_ALERT_NOTIFICATION,
  payload,
});

export const setResolvedNotification = (payload) => ({
  type: actionTypes.ACTION_SET_RESOLVED_NOTIFICATION,
  payload,
});

export const removeResolvedNotification = () => ({
  type: actionTypes.ACTION_REMOVE_RESOLVED_NOTIFICATION,
});

export const addNotification = (payload) => ({
  type: actionTypes.ACTION_ADD_NOTIFICATION,
  payload,
});

export const checkNotifications = () => ({
  type: actionTypes.ACTION_CHECK_NOTIFICATIONS,
});

export const updateNotification = (payload) => ({
  type: actionTypes.ACTION_UPDATE_NOTIFICATION,
  payload,
});

export const setChainId = (payload) => ({
  type: actionTypes.ACTION_SET_CHAIN_ID,
  payload,
});

export const setUserDeals = (payload) => ({
  type: actionTypes.ACTION_SET_USER_DEALS,
  payload,
});

export const setPollList = (payload) => ({
  type: actionTypes.ACTION_SET_POLL_LIST,
  payload,
});

export const setOldDeals = (payload) => ({
  type: actionTypes.ACTION_SET_OLD_DEALS,
  payload,
});

export const deleteUserDeal = (payload) => ({
  type: actionTypes.ACTION_DELETE_USER_DEAL,
  payload,
});

export const setUserDeal = (payload) => ({
  type: actionTypes.ACTION_SET_USER_DEAL,
  payload,
});

export const setOldDeal = (payload) => ({
  type: actionTypes.ACTION_SET_OLD_DEAL,
  payload,
});

export const addUserDeal = (payload) => ({
  type: actionTypes.ACTION_ADD_USER_DEAL,
  payload,
});

export const setActiveHashes = (payload) => ({
  type: actionTypes.ACTION_SET_ACTIVE_HASHES,
  payload,
});

export const removeActiveHash = (payload) => ({
  type: actionTypes.ACTION_REMOVE_ACTIVE_HASH,
  payload,
});

export const setBackupDeal = (payload) => ({
  type: actionTypes.ACTION_SET_BACKUP_DEAL,
  payload,
});

export const removeBackupDeal = () => ({
  type: actionTypes.ACTION_REMOVE_BACKUP_DEAL,
});

export const addDealToAnimate = (payload) => ({
  type: actionTypes.ACTION_ADD_DEAL_TO_ANIMATE,
  payload,
});

export const removeAnimateDeal = () => ({
  type: actionTypes.ACTION_REMOVE_ANIMATE_DEAL,
});

export const addAccountInfoAnimation = (payload) => ({
  type: actionTypes.ACTION_ADD_ACCOUNT_INFO_ANIMATION,
  payload,
});

export const removeAccountInfoAnimation = () => ({
  type: actionTypes.ACTION_REMOVE_ACCOUNT_INFO_ANIMATION,
});

export const closeSidebar = () => ({
  type: actionTypes.ACTION_CLOSE_SIDEBAR,
});

export const openSidebar = () => ({
  type: actionTypes.ACTION_OPEN_SIDEBAR,
});

export const handleContribute = (payload) => ({
  type: actionTypes.ACTION_HANDLE_CONTRIBUTE,
  payload,
});

export const handleV2Contribute = (payload) => ({
  type: actionTypes.ACTION_HANDLE_V2_CONTRIBUTE,
  payload,
});

export const resetGlobal = () => ({
  type: actionTypes.ACTION_RESET_GLOBAL,
});

export const updateDeal = (payload) => ({
  type: actionTypes.ACTION_UPDATE_DEAL,
  payload,
});

export const setWeb3Provider = (payload) => ({
  type: actionTypes.ACTION_SET_WEB3_PROVIDER,
  payload,
});

export const setWeb3Signer = (signer) => ({
  type: actionTypes.ACTION_SET_WEB3_SIGNER,
  payload: signer,
});
