/* global BigInt */
import React, { useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Divider } from '@material-ui/core';
import './index.scss';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import SvgIcon from 'components/svgIcon';
import { utils } from 'ethers';
import { addTokenToMetamask, addNetwork, getNetworkBasicNameById } from 'contracts/browserWallet';
import moment from 'moment';
import { getTransactionLinkByChainName } from 'contracts/explorers';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import RoundedButton from 'components/button/rounded-button';
import numeral from 'numeral';

const calculatePercentage = (tokensClaimed, tokensExpected) => {
  if (!tokensClaimed || !tokensExpected) return 0.0;
  return parseFloat((tokensClaimed / tokensExpected) * 100).toFixed(2);
};

const ClaimCheckModal = ({ open, deal, onClose }) => {
  const globalReducer = useSelector((state) => state.global);
  const { chainId } = globalReducer;

  const claims = useMemo(
    () =>
      deal.claimers
        .map((claimer) =>
          claimer.claims?.map((claim) => ({
            ...claim,
            token:
              claimer?.claimableAmounts?.find(
                ({ token }) => token.address?.toLowerCase() === claim.tokenAddress?.toLowerCase()
              )?.token || claimer.token,
            chain: getNetworkBasicNameById(claimer.chainId),
          }))
        )
        .flat(),
    [deal]
  );

  const totalClaimed = useMemo(() => {
    return claims
      .reduce(
        (accum, { amount, token }) =>
          accum + +utils.formatUnits(BigInt(amount), token?.decimals || 18),
        0
      )
      .toFixed(2);
  }, [claims]);

  const addToMetamask = async (claim) => {
    const networkChecked = await addNetwork(chainId, claim.network);

    if (networkChecked) {
      setTimeout(() => {
        addTokenToMetamask(claim.tokenAddress, claim.symbol, claim.decimals);
      }, 500);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <div className="claim-deal-check-modal">
        <div className="claim-deal-check-modal__head">
          <div>
            <RoundedAvatar src={deal.imageUrl} />

            <div>
              <span>{deal.name}</span>
              {deal.dealSize && deal.raisedAmount && (
                <CustomProgressBar
                  total={Number(deal.dealSize)}
                  value={Number(deal.raisedAmount)}
                />
              )}
            </div>
          </div>
          <RoundedButton onClick={onClose}>Close</RoundedButton>
        </div>
        <Divider />
        <div className="claim-deal-check-modal__table">
          <div className="claim-deal-check-modal__table-head">
            <span>Token</span>
            <span>Blockchain</span>
            <span>Claimed</span>
            <span>Date Claimed</span>
            <span className="small-column">TX link</span>
            <span className="small-column">Add Token</span>
          </div>
          <div className="claim-deal-check-modal__table-body">
            {claims.map((claim, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  <span>{claim.token && `${claim.token.name} (${claim.token.symbol})`}</span>
                  <span>{claim.chain.toUpperCase()}</span>
                  <span className="amount-wrapper">
                    <NumberFormat
                      value={utils.formatUnits(BigInt(claim.amount), claim.token?.decimals || 18)}
                      thousandSeparator
                      displayType="text"
                      decimalScale={0}
                    />
                  </span>
                  <span>{moment(claim.dateClaimed).format('D-MMM-YYYY')}</span>
                  <span className="small-column">
                    <a
                      href={getTransactionLinkByChainName(claim.chain, claim.transactionTx)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SvgIcon name="iconExternalLink" />
                    </a>
                  </span>
                  <button
                    className="metamask-button small-column"
                    type="button"
                    onClick={() => addToMetamask(claim)}
                  >
                    <SvgIcon name="metamaskFox" />
                  </button>
                </div>
              );
            })}
          </div>
          <div className="totals-container">
            {deal.tokensExpected && (
              <div className="total-container">
                <span>Total Expected: {numeral(deal.tokensExpected).format('0,0')}</span>
              </div>
            )}
            {totalClaimed && (
              <div className="total-container">
                <span>
                  Total Claimed:{' '}
                  <NumberFormat
                    value={totalClaimed}
                    thousandSeparator
                    displayType="text"
                    decimalScale={0}
                  />
                </span>
                <span className="claimed-percentage">
                  [{calculatePercentage(totalClaimed, deal.tokensExpected)}%]
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ClaimCheckModal;
