// global
export const ACTION_INIT_GLOBAL = 'ACTION_INIT_GLOBAL';
export const ACTION_UPDATE_GLOBAL = 'ACTION_UPDATE_GLOBAL';
export const ACTION_UPDATE_LOADING = 'ACTION_UPDATE_LOADING';
export const ACTION_UPDATE_FETCHING_DEALS = 'ACTION_UPDATE_FETCHING_DEALS';
export const ACTION_SET_SHARED_NOTIFICATION = 'ACTION_SET_SHARED_NOTIFICATION';
export const ACTION_SET_ALERT_NOTIFICATION = 'ACTION_SET_ALERT_NOTIFICATION';
export const ACTION_SET_RESOLVED_NOTIFICATION = 'ACTION_SET_RESOLVED_NOTIFICATION';
export const ACTION_REMOVE_RESOLVED_NOTIFICATION = 'ACTION_REMOVE_RESOLVED_NOTIFICATION';
export const ACTION_ADD_NOTIFICATION = 'ACTION_ADD_NOTIFICATION';
export const ACTION_CHECK_NOTIFICATIONS = 'ACTION_CHECK_NOTIFICATIONS';
export const ACTION_UPDATE_NOTIFICATION = 'ACTION_UPDATE_NOTIFICATION';
export const ACTION_SET_CHAIN_ID = 'ACTION_SET_CHAIN_ID';
export const ACTION_SET_USER_DEALS = 'ACTION_SET_USER_DEALS';
export const ACTION_SET_OLD_DEALS = 'ACTION_SET_OLD_DEALS';
export const ACTION_SET_USER_DEAL = 'ACTION_SET_USER_DEAL';
export const ACTION_ADD_USER_DEAL = 'ACTION_ADD_USER_DEAL';
export const ACTION_DELETE_USER_DEAL = 'ACTION_DELETE_USER_DEAL';
export const ACTION_SET_OLD_DEAL = 'ACTION_SET_OLD_DEAL';
export const ACTION_SET_ACTIVE_HASHES = 'ACTION_SET_ACTIVE_HASHES';
export const ACTION_REMOVE_ACTIVE_HASH = 'ACTION_REMOVE_ACTIVE_HASH';
export const ACTION_SET_BACKUP_DEAL = 'ACTION_SET_BACKUP_DEAL';
export const ACTION_REMOVE_BACKUP_DEAL = 'ACTION_REMOVE_BACKUP_DEAL';
export const ACTION_ADD_DEAL_TO_ANIMATE = 'ACTION_ADD_DEAL_TO_ANIMATE';
export const ACTION_REMOVE_ANIMATE_DEAL = 'ACTION_REMOVE_ANIMATE_DEAL';
export const ACTION_ADD_ACCOUNT_INFO_ANIMATION = 'ACTION_ADD_ACCOUNT_INFO_ANIMATION';
export const ACTION_REMOVE_ACCOUNT_INFO_ANIMATION = 'ACTION_REMOVE_ACCOUNT_INFO_ANIMATION';
export const ACTION_CLOSE_SIDEBAR = 'ACTION_CLOSE_SIDEBAR';
export const ACTION_OPEN_SIDEBAR = 'ACTION_OPEN_SIDEBAR';
export const ACTION_HANDLE_CONTRIBUTE = 'ACTION_HANDLE_CONTRIBUTE';
export const ACTION_HANDLE_V2_CONTRIBUTE = 'ACTION_HANDLE_V2_CONTRIBUTE';
export const ACTION_RESET_GLOBAL = 'ACTION_RESET_GLOBAL';
export const ACTION_UPDATE_DEAL = 'ACTION_UPDATE_DEAL';
export const ACTION_SET_POLL_LIST = 'ACTION_SET_POLL_LIST';
export const ACTION_UPDATE_SYSTEM_PART = 'ACTION_UPDATE_SYSTEM_PART';
export const ACTION_SET_WEB3_PROVIDER = 'ACTION_SET_WEB3_PROVIDER';
export const ACTION_SET_WEB3_SIGNER = 'ACTION_SET_WEB3_SIGNER';

// auth
export const ACTION_INIT_AUTH = 'ACTION_INIT_AUTH';
export const ACTION_UPDATE_AUTH = 'ACTION_UPDATE_AUTH';
export const ACTION_SET_FINAL_BLOCK_NUMBER = 'ACTION_SET_FINAL_BLOCK_NUMBER';
export const ACTION_LOGOUT_USER = 'ACTION_LOGOUT_USER';
export const ACTION_SET_ID_VERIFIED = 'ACTION_SET_ID_VERIFIED';
export const ACTION_ADD_PROFILE_WALLET = 'ACTION_ADD_PROFILE_WALLET';
export const ACTION_SET_PROFILE_WALLETS = 'ACTION_SET_PROFILE_WALLETS';
export const ACTION_SET_PROFILE_IS_US = 'ACTION_SET_PROFILE_IS_US';
export const ACTION_SUB_BDT_BALANCE = 'ACTION_SUB_BDT_BALANCE';
export const ACTION_ADD_BDT_BALANCE = 'ACTION_ADD_BDT_BALANCE';
export const ACTION_SUB_USDT_BALANCE = 'ACTION_SUB_USDT_BALANCE';
export const ACTION_ADD_USDT_BALANCE = 'ACTION_ADD_USDT_BALANCE';
export const ACTION_RESET_AUTH = 'ACTION_RESET_AUTH';
