import React, { useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';
import Tooltip from 'components/tooltip';
import { useSelector } from 'react-redux';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { PoolStatus } from 'contracts/poolManager/constants';
import PledgeModal from './PledgeModal';
import ReleaseModal from './ReleaseModal';

export default function UserPledgeControl({ deal, onLoad }) {
  const authReducer = useSelector((state) => state.auth);
  const { accountInfo } = authReducer;

  const [noPledgeMessage, setNoPledgeMessage] = useState('');

  const [pledgeModalOpen, setPledgeModalOpen] = useState(false);
  const [releaseModalOpen, setReleaseModalOpen] = useState(false);

  const handleNoPledge = (accountInfo) => {
    if (!accountInfo) return;
    if (accountInfo.userAccessLevel === -1 && accountInfo.relockMessage?.length) {
      setNoPledgeMessage(accountInfo.relockMessage);
      return;
    }

    setNoPledgeMessage('');
  };

  const { pool, isLoading: isPoolLoading } = usePoolQuery(deal.address);

  if (![1, 7].includes(deal.statusId) || isPoolLoading) return null;

  return (
    <>
      <Tooltip title={noPledgeMessage}>
        <div
          className="deal__field deal__field-status-stepper vertical-center vertical-gap"
          onMouseEnter={() => handleNoPledge(accountInfo)}
        >
          <RoundedButton
            disabled={!deal.pledge || pool?.status !== PoolStatus.OpenUnlocked}
            type="secondary"
            onClick={() => setReleaseModalOpen(true)}
          >
            Release
          </RoundedButton>
          <RoundedButton
            type="primary"
            disabled={accountInfo.userAccessLevel === -1}
            onClick={() => setPledgeModalOpen(true)}
          >
            Pledge
          </RoundedButton>
        </div>
      </Tooltip>
      <PledgeModal
        poolAddress={deal.address}
        isOpen={pledgeModalOpen}
        onCancel={() => setPledgeModalOpen(false)}
        defaultValue={deal.pledge}
        onLoad={onLoad}
      />
      <ReleaseModal
        poolAddress={deal.address}
        isOpen={releaseModalOpen}
        onCancel={() => setReleaseModalOpen(false)}
        onLoad={onLoad}
      />
    </>
  );
}
